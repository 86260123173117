import { throttle } from 'lodash'
import { createContext, FC, useContext, useEffect, useReducer } from 'react'
import { useSlideMapViewSelector } from 'viewer/container'

import { useViewerIdSlideState, useViewerPageProvided } from './ViewerPageProvider'

/**
 * Тротлинг изменения параметров viewCenter, viewZoom, viewRotation
 * Эти значения сразу видно на UI. Оптимально поставлено - 50мс.
 * @constant
 */
const VIEW_STATE_CHANGE_THROTTLE_TIME_MS = 50

/**
 * Тип для контекста с состоянием карты и функцией его изменения.
 * @typedef {Object} TMapViewInfoContext
 * @property {IMapViewState} viewState - Текущее состояние вида карты.
 * @property {(viewState: IMapViewAction) => void} setViewState - Функция для установки нового состояния карты.
 */
type TMapViewInfoContext = {
  viewState: IMapViewState
  setViewState: (viewState: IMapViewAction) => void
}

/**
 * Начальное состояние карты.
 * @constant
 * @type {IMapViewState}
 */
export const mapViewInitialState: IMapViewState = {
  center: [0, 0],
  rotation: 0,
  zoom: 1,
}

const MapViewInfoContext = createContext<TMapViewInfoContext>({
  setViewState: () => {},
  viewState: mapViewInitialState,
})

/**
 * Хук для использования контекста состояния карты.
 * @returns {TMapViewInfoContext} Текущий контекст состояния карты.
 */
export const useMapViewInfoContext = (): TMapViewInfoContext => useContext(MapViewInfoContext)

/**
 * Провайдер контекста состояния карты.
 *
 * ВАЖНО: Используется для немедленного обновления значений в UI или для сайд-эффектов, вызванных действиями пользователя.
 * Сделано в рамках оптимизации вьювера.
 *
 * Если значения не нужны сразу (например, для сохранения в localStorage), то не следует добавлять их в этот контекст.
 * Присмотритесь к контексту `MapsProvider`.
 *
 * Если вы обновляете провайдер, обязательно проверяйте производительность и минимизируйте лишние ререндеры.
 */
const MapViewInfoProvider: FC = ({ children }) => {
  const activeViewerId = useViewerPageProvided().activeViewerId
  const slideId = useViewerIdSlideState(activeViewerId).slideId

  const { viewCenter, viewRotation, viewZoom } = useSlideMapViewSelector({ slideId, viewerId: activeViewerId })
  const { center, rotation, zoom } = mapViewInitialState
  const [viewState, dispatchViewState] = useReducer(mapViewReducer, {
    center: viewCenter ?? center,
    rotation: viewRotation ?? rotation,
    zoom: viewZoom ?? zoom,
  })

  /**
   * Обновление состояния карты при изменении идентификатора слайда.
   *
   * ВАЖНО: Решил сюда что-то добавить? Посмотри доку к контексту. ☝️
   */
  useEffect(() => {
    if (viewCenter !== undefined && viewRotation !== undefined && viewZoom !== undefined) {
      const reduxViewState: IMapViewState = {
        center: viewCenter,
        rotation: viewRotation,
        zoom: viewZoom,
      }

      dispatchViewState({ payload: reduxViewState, type: 'batchUpdate' })
    }
  }, [slideId])

  /**
   * Установка нового состояния карты с тротлингом.
   * @param {IMapViewAction} viewState - Действие для изменения состояния карты.
   */
  const setViewState = throttle((viewState: IMapViewAction) => {
    dispatchViewState(viewState)
  }, VIEW_STATE_CHANGE_THROTTLE_TIME_MS)

  /** ВАЖНО: Решил сюда что-то добавить? Посмотри доку к контексту. ☝️ */

  return <MapViewInfoContext.Provider value={{ setViewState, viewState }}>{children}</MapViewInfoContext.Provider>
}

export default MapViewInfoProvider

/**
 * Редьюсер для управления состоянием карты.
 * @param {IMapViewState} state - Текущее состояние карты.
 * @param {IMapViewAction} action - Действие, которое изменяет состояние карты.
 * @returns {IMapViewState} Новое состояние карты.
 */
const mapViewReducer = (state: IMapViewState, action: IMapViewAction): IMapViewState => {
  switch (action.type) {
    case 'zoom':
      return { ...state, zoom: action.payload }
    case 'center':
      return { ...state, center: action.payload }
    case 'rotation':
      return { ...state, rotation: action.payload }
    case 'batchUpdate':
      return action.payload
    default:
      return state
  }
}

/**
 * Интерфейс для состояния карты.
 * @typedef {Object} IMapViewState
 * @property {number[]} center - Координаты центра карты.
 * @property {number} rotation - Угол поворота карты.
 * @property {number} zoom - Уровень зума карты.
 */
export interface IMapViewState {
  center: number[]
  rotation: number
  zoom: number
}

/**
 * Тип для действий редьюсера состояния карты.
 * @typedef {Object} IMapViewAction
 * @property {'zoom'|'center'|'rotation'|'batchUpdate'} type - Тип действия.
 * @property {number|number[]|IMapViewState} payload - Значение, которое будет изменено.
 */
type IMapViewAction =
  | { type: 'zoom'; payload: number }
  | { type: 'center'; payload: number[] }
  | { type: 'rotation'; payload: number }
  | { type: 'batchUpdate'; payload: IMapViewState }
