import { useTypedSelector } from 'app/redux/lib/selector'
import { debounce } from 'lodash'
import { slideMapViewSlice } from 'pages/viewer/model/slideMapViewSlice'
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { KeyboardPan, KeyboardZoom } from 'shared/lib/map/lib/interactions'
import TViewerId from 'types/TViewerId'

import { IMapViewState, mapViewInitialState } from './MapViewInfoProvider'
import { useViewerPageProvided } from './ViewerPageProvider'

/**
 * Дебаунс изменения параметров viewCenter, viewZoom, viewRotation в редакс.
 * @constant
 */
const SET_CURRENT_PARAMS_DEBOUNCE_TIME_MS = 400

type IOriginParams = {
  origZoom: number
  origRotation: number
  origCenter: number[]
}
type IOriginMapsParams = {
  [key in TViewerId]?: IOriginParams
}
export type ICurrentMapsParams = {
  [key in TViewerId]?: IMapViewState
}
export type ICurrentParamKey = 'zoom' | 'center' | 'rotation'

type IMapParamsContext = {
  currentParams: ICurrentMapsParams
  originParams: IOriginMapsParams
  getOriginParams: (viewerId: TViewerId) => IOriginParams
  getCurrentParams: (viewerId: TViewerId) => IMapViewState
  setOriginParams: (viewerId: TViewerId, newParams: IOriginParams) => void
  setCurrentParam: (viewerId: TViewerId, key: ICurrentParamKey, value: any, silent?: boolean) => void
}

const DEFAULT_ORIGIN: IOriginParams = {
  origCenter: [0, 0],
  origRotation: 0,
  origZoom: 1,
}

const MapParamsContext = createContext<IMapParamsContext>({
  currentParams: {},
  getCurrentParams: () => Object.assign({}, mapViewInitialState),
  getOriginParams: () => Object.assign({}, DEFAULT_ORIGIN),
  originParams: {},
  setCurrentParam: () => {},
  setOriginParams: () => {},
})

type Props = {
  children: ReactNode
}

const MapParamsProvider = ({ children }: Props) => {
  const { isAnyInputFocusing } = useTypedSelector((state) => state.viewerPage)
  const { viewingState } = useViewerPageProvided()
  /**
   * Reference for access to map origin params in runtime callbacks by viewer id
   */
  const [originParams, _updateOriginParams] = useState<IOriginMapsParams>({
    A: Object.assign({}, DEFAULT_ORIGIN),
  })
  const setOriginParams = useCallback(
    (viewerId: TViewerId, newParams: IOriginParams) => {
      _updateOriginParams({
        ...originParams,
        [viewerId]: newParams,
      })
    },
    [originParams],
  )
  const getOriginParams = useCallback(
    (viewerId: TViewerId) => originParams[viewerId] || Object.assign({}, DEFAULT_ORIGIN),
    [originParams],
  )
  /**
   * Reference and switch-state pair for updating map params in children
   */
  const dispatch = useDispatch()

  const currentParams = useRef<ICurrentMapsParams>({
    A: Object.assign({}, mapViewInitialState),
  })
  const getCurrentParams = useCallback(
    (viewerId: TViewerId): IMapViewState => {
      const params = currentParams.current[viewerId]
      if (params === undefined) return Object.assign({}, mapViewInitialState)
      else return params
    },
    [currentParams.current],
  )

  const _setCurrentParam = useCallback(
    (viewerId: TViewerId, key: ICurrentParamKey, value: number | number[]) => {
      const params = currentParams.current
      const vidParams = params[viewerId]
      if (vidParams === undefined) return
      const param = vidParams[key]
      if (param === undefined) return
      currentParams.current = {
        ...params,
        [viewerId]: {
          ...vidParams,
          [key]: value,
        },
      }
    },
    [currentParams.current],
  )

  /**
   * Отдача параметров в редакс
   */
  const setCurrentParam = useCallback(
    debounce((viewerId: TViewerId, key: ICurrentParamKey, value: number | number[], silent = false) => {
      _setCurrentParam(viewerId, key, value)
      if (silent) return

      const state = viewingState[viewerId]
      const params = currentParams.current[viewerId]

      if (state === undefined || params === undefined) return

      const { slideId } = state.slide

      switch (key) {
        case 'zoom':
          dispatch(slideMapViewSlice.actions.setZoom({ slideId, viewerId, zoom: params.zoom }))
          break
        case 'center':
          dispatch(slideMapViewSlice.actions.setCenter({ center: params.center, slideId, viewerId }))
          break
        case 'rotation':
          dispatch(slideMapViewSlice.actions.setRotation({ rotation: params.rotation, slideId, viewerId }))
          break
      }
    }, SET_CURRENT_PARAMS_DEBOUNCE_TIME_MS),
    [viewingState],
  )

  /**
   * Эффект синхронизации с viewing state
   */
  useEffect(() => {
    const newCurrentParams: ICurrentMapsParams = {}
    const oldCurrentParams = currentParams.current
    Object.keys(viewingState).forEach((id) => {
      const vid = id as TViewerId
      if (oldCurrentParams[vid] === undefined) {
        newCurrentParams[vid] = Object.assign({}, mapViewInitialState)
      } else {
        newCurrentParams[vid] = Object.assign({}, oldCurrentParams[vid])
      }
    })
    currentParams.current = newCurrentParams
  }, [viewingState])

  useEffect(() => {
    const setInteractionActive = (value: boolean) => {
      Object.keys(viewingState).forEach((id) => {
        const vid = id as TViewerId
        const state = viewingState[vid]
        if (state === undefined) return
        const { map } = state
        const interactions = map.getInteractions().getArray()
        interactions
          .filter((int) => int instanceof KeyboardPan || int instanceof KeyboardZoom)
          .forEach((int) => {
            int.setActive(value)
          })
      })
    }
    setInteractionActive(isAnyInputFocusing === false)
  }, [isAnyInputFocusing])

  return (
    <MapParamsContext.Provider
      value={{
        currentParams: currentParams.current,
        getCurrentParams,
        getOriginParams,
        originParams,
        setCurrentParam,
        setOriginParams,
      }}
    >
      {children}
    </MapParamsContext.Provider>
  )
}

const useMapParamsProvided = () => useContext(MapParamsContext)

const useViewerMapParams = (viewerId: TViewerId) => {
  const { originParams } = useMapParamsProvided()
  //do not use memoization of originParams[viewerId] by viewerId to get actually originParams
  return originParams[viewerId]
}

export { MapParamsProvider, useMapParamsProvided, useViewerMapParams }
