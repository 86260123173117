import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { useCallback } from 'react'
import { localizeDictionary } from 'shared/lib/common'
import i18next from 'shared/lib/i18n/i18n'
import { DictionaryItem } from 'types/IDictionary'

import SiteSelectElement from './ui/SiteSelectElement'

const t = i18next.t

type Props = {
  onChange?: (sites: { site: DictionaryItem }[]) => void
  value?: { site: DictionaryItem }[]
  placeholder?: string
}

export const SiteSelectContainer = ({
  onChange,
  placeholder = t('Поиск по названию или коду…'),
  value,
  ...props
}: Props) => {
  const dictionary = useLiveQuery(() => getDataFromDB('SITE')) as TDict

  const { data, updatedAt } = { ...dictionary }
  const dictionaryLocales: DictionaryItem[] = localizeDictionary(data, DictionaryNS.SITE)

  const onSelect = useCallback(
    (id: number) => {
      if (dictionaryLocales) {
        const site = dictionaryLocales.find((item) => item.id === id)
        if (!onChange) return
        site ? onChange([{ site }]) : onChange([])
        // console.log([site])
      }
    },
    [updatedAt],
  )

  const site = value?.length ? value[0]?.site.id : undefined
  return (
    <SiteSelectElement
      style={{
        width: '100%',
      }}
      value={site}
      placeholder={placeholder}
      onChange={onSelect as any}
      sites={dictionaryLocales}
      {...props}
    />
  )
}
