import { Slider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { throttle } from 'lodash'
import { useMapViewInfoContext } from 'pages/viewer/lib/common/MapViewInfoProvider'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useEventBusProvided } from 'shared/lib/EventBus'
import { IconElement } from 'shared/ui/kit'
import { CustomInputElement } from 'shared/ui/kit/ui/CustomInputElement'
import { ToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import { VerticalSeparator } from 'shared/ui/kit/ui/VerticalSeparator'
import styled from 'styled-components'

const StyledRotationToolsPanel = styled.div`
  display: flex;
  align-items: center;
`

const StyledSlider = styled(Slider)`
  flex: 0 0 100px;
  margin: 0 8px;
  height: 8px;

  .ant-slider-handle {
    background-color: #fff;
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }

  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`

/** Максимальный угол поворота */
const MAX_ROTATION_ANGLE = 360
/**
 * Тротлинг слайдера поворота слайда
 * Оптимально до 50мс. Увеличение заметно на глаз.
 */
const ROTATION_SLIDER_THROTTLE_TIME_MS = 50

export const RotationToolsPanel = () => {
  const dispatch = useDispatch()
  const { activeViewerId } = useOpenViewers()
  const bus = useEventBusProvided()
  const { slideId } = useViewerIdSlideState(activeViewerId)
  const isAnimatingIntoAnnotation = useTypedSelector(
    (state) => state.viewers[activeViewerId].viewer.isAnimatingIntoAnnotation,
  )
  const { unsubscribeFromUser } = useUserStatusContext()
  const {
    viewState: { rotation: viewRotation },
  } = useMapViewInfoContext()

  const setIsAnyInputFocusing = (value: boolean) => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  }

  const rotationDegrees = useMemo(() => {
    if (viewRotation === 0 || viewRotation === undefined) return 0
    return (viewRotation * 180) / Math.PI
  }, [viewRotation, activeViewerId])

  const on90DegRotate = () => {
    unsubscribeFromUser()
    const finalRotation = rotationDegrees !== undefined ? (rotationDegrees + 90) % 360 : 90
    emitRotate(finalRotation)
  }

  const emitRotate = useCallback(
    (value) => {
      !isAnimatingIntoAnnotation && bus.$emit('tool:rotate', activeViewerId, value)
    },
    [activeViewerId, isAnimatingIntoAnnotation],
  )

  const onSliderChange = throttle((value: number) => {
    unsubscribeFromUser()
    emitRotate(value)
  }, ROTATION_SLIDER_THROTTLE_TIME_MS)

  const rotationDegreesFormatter = useCallback(
    (val?: number) => {
      if (!val) return 0
      const numericVal = Number(val)
      const fullDegrees = 360
      const formattedVal =
        numericVal > fullDegrees ? numericVal - fullDegrees : numericVal < 0 ? numericVal + fullDegrees : numericVal
      if (formattedVal > fullDegrees) {
        const calculateCircle = formattedVal / fullDegrees
        const calculateRotationDegrees = formattedVal - fullDegrees * Math.floor(calculateCircle)
        return calculateRotationDegrees
      } else {
        return formattedVal
      }
    },
    [activeViewerId],
  )

  const handleChange = (e: BaseSyntheticEvent) => {
    unsubscribeFromUser()
    const str: string = e.target.value
    if (Number(str) <= MAX_ROTATION_ANGLE) {
      emitRotate(String(rotationDegreesFormatter(Math.round(+str))))
    }
  }

  const onClickIcon = (val: string) => {
    unsubscribeFromUser()
    emitRotate(String(rotationDegreesFormatter(Math.round(+val))))
  }

  const onBlur = () => {
    if (!rotationDegrees) {
      emitRotate('0')
    }
  }

  useEffect(() => {
    emitRotate(String(rotationDegreesFormatter(Math.round(rotationDegrees))))
  }, [slideId])

  return (
    <StyledRotationToolsPanel onFocus={() => setIsAnyInputFocusing(true)} onBlur={() => setIsAnyInputFocusing(false)}>
      <CustomInputElement
        min={0}
        type={'text'}
        max={MAX_ROTATION_ANGLE}
        value={Math.round(rotationDegreesFormatter(rotationDegrees))}
        prefixElement={<IconElement name={'angle'} size={'md'} fill={'var(--color-text-3)'} />}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={(e: BaseSyntheticEvent) => e.target.select()}
        onClickIcon={onClickIcon}
      />
      <StyledSlider
        min={0}
        max={MAX_ROTATION_ANGLE}
        tipFormatter={null}
        // Тултип ощутимо снижает производительность
        // tipFormatter={(value) => `${value}°`}
        value={rotationDegreesFormatter(rotationDegrees)}
        onChange={onSliderChange}
      />
      <VerticalSeparator style={{ marginRight: 8 }} />
      <ToolbarButton onClick={on90DegRotate} icon={<IconElement name={'rotate90Right'} />} />
    </StyledRotationToolsPanel>
  )
}
